<template>
  <div class="bg">
    <v-container fill-height fluid>
      <v-row align="center">
        <v-col>
          <v-card
            class="mx-auto"
            max-width="1000"
            min-height="565"
            align="right"
            :id="'main_login_image' + selected_image"
          >
            <v-card
              max-width="350"
              min-height="565"
              align="center"
              class="rounded-l-0"
              flat
            >
              <v-container>
                <v-img
                  class="mt-1"
                  v-if="$vuetify.theme.dark"
                  src="@/assets/LogoV2-White_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
                <v-img
                  class="mt-1"
                  v-if="!$vuetify.theme.dark"
                  src="@/assets/LogoV2-Black_ClearBknd.png"
                  max-height="100"
                  max-width="200"
                ></v-img>
              </v-container>

              <v-stepper v-model="stepperCount" class="elevation-0">
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :complete="stepperCount > 1"
                    step="1"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepperCount > 2"
                    step="2"
                    color="primary_blue"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepperCount > 3"
                    step="3"
                    color="primary_blue"
                  >
                  </v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepperCount > 4"
                    step="4"
                    color="primary_blue"
                  >
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items id="register_stepper">
                  <!-- Enter email form -->
                  <v-stepper-content class="pt-2" step="1">
                    <span>Create your account </span>
                    <v-form
                      ref="form-register-1"
                      class="mb-2"
                      v-model="cognito_form_valid"
                    >
                      <v-text-field
                        v-model="user.cognito.email"
                        label="Email*"
                        class="mt-6"
                        outlined
                        type="email"
                        clearable
                        color="primary_blue"
                        :rules="[validateEmailRegister]"
                      >
                      </v-text-field>

                      <v-text-field
                        v-model="user.cognito.password"
                        label="Password*"
                        outlined
                        color="primary_blue"
                        clearable
                        :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="() => (visible = !visible)"
                        :type="visible ? 'text' : 'password'"
                        :rules="[validatePassword]"
                      >
                      </v-text-field>

                      <v-text-field
                        v-model="confirm_password"
                        label="Confirm Password*"
                        outlined
                        color="primary_blue"
                        clearable
                        :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="() => (visible = !visible)"
                        :type="visible ? 'text' : 'password'"
                        :rules="[
                          validateSamePassword(
                            this.user.cognito.password,
                            this.confirm_password
                          ),
                        ]"
                      >
                      </v-text-field>
                    </v-form>
                    <v-btn
                      elevation="4"
                      large
                      color="primary_blue"
                      type="submit"
                      :disabled="!cognito_form_valid"
                      :dark="cognito_form_valid"
                      @click="stepperCount = 2"
                    >
                      Next
                    </v-btn>
                    <span
                      class="mt-2 blue_on_hover"
                      style="cursor: pointer; display: block"
                      @click="$router.push({ path: '/login' })"
                    >
                      Login instead
                    </span>
                  </v-stepper-content>
                  <v-stepper-content class="pt-0" step="2">
                    <v-form
                      ref="form-register-2"
                      class=""
                      v-model="dynamo_form_valid"
                    >
                      <v-text-field
                        v-model="user.dynamo.first_name"
                        label="First Name*"
                        class="mt-5"
                        hide-details
                        outlined
                        color="primary_blue"
                        :rules="[validateNotEmpty]"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="user.dynamo.last_name"
                        label="Last Name"
                        class="mt-5"
                        hide-details
                        outlined
                        color="primary_blue"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="user.dynamo.city"
                        label="City*"
                        class="mt-5"
                        hide-details
                        outlined
                        color="primary_blue"
                        :rules="[validateNotEmpty]"
                      >
                      </v-text-field>
                      <v-autocomplete
                        v-model="user.dynamo.country"
                        label="Country"
                        class="mt-5"
                        outlined
                        color="primary_blue"
                        :items="countries"
                        :rules="[validateCountry]"
                      >
                      </v-autocomplete>
                    </v-form>
                    <v-btn
                      elevation="4"
                      large
                      color=""
                      type="submit"
                      class="mr-1 my-2"
                      @click="stepperCount = 1"
                    >
                      Back
                    </v-btn>

                    <v-btn
                      elevation="4"
                      large
                      color="primary_blue"
                      type="submit"
                      class="ml-1 my-2"
                      v-if="!dynamo_form_valid"
                      disabled
                      :dark="dynamo_form_valid"
                      @click="stepperCount = 3"
                    >
                      Next
                    </v-btn>
                    <v-btn
                      elevation="4"
                      large
                      color="primary_blue"
                      type="submit"
                      class="ml-1 my-2"
                      v-else
                      dark
                      @click="stepperCount = 3"
                    >
                      Next
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-form
                      ref="form-register-3"
                      class="mb-4"
                      v-model="referred_form_valid"
                    >
                      <v-text-field
                        v-model="user.dynamo.company"
                        label="Company*"
                        class="mt-6"
                        hide-details
                        outlined
                        color="primary_blue"
                        :rules="[validateNotEmpty]"
                      >
                      </v-text-field>

                      <v-text-field
                        v-model="user.dynamo.occupation"
                        label="Occupation*"
                        class="mt-6"
                        hide-details
                        outlined
                        color="primary_blue"
                        :rules="[validateNotEmpty]"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="user.dynamo.referred_by"
                        label="How did you hear about us?*"
                        class="mt-6"
                        outlined
                        hide-details
                        color="primary_blue"
                        :rules="[validateNotTooLong, validateNotEmpty]"
                      >
                      </v-text-field>
                    </v-form>
                    <v-btn
                      elevation="4"
                      large
                      color=""
                      type="submit"
                      class="mr-1 my-2"
                      @click="stepperCount = 2"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      elevation="4"
                      large
                      color="primary_blue"
                      type="submit"
                      class="ml-1 my-2"
                      :disabled="!referred_form_valid"
                      :dark="referred_form_valid"
                      @click="register()"
                    >
                      Register
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <span
                      >We have sent a confirmation code to your email, enter the
                      code below to continue
                    </span>
                    <v-form
                      ref="form-validate"
                      @submit.prevent.stop="verify_email()"
                      class="mb-4"
                      v-model="verification_form_valid"
                    >
                      <v-text-field
                        v-model="verification_code"
                        label="Verification code"
                        class="mt-6"
                        hide-details
                        outlined
                        color="primary_blue"
                        :rules="[validateNotEmpty]"
                      >
                      </v-text-field>
                    </v-form>

                    <v-btn
                      elevation="4"
                      large
                      color="primary_blue"
                      type="submit"
                      class="ml-1 my-2"
                      :disabled="!verification_form_valid"
                      :dark="verification_form_valid"
                      @click="verify_email()"
                    >
                      Verify Email
                    </v-btn>
                    <span
                      class="mt-4"
                      style="color: #607d8b; display: block"
                      v-if="resend_verification_time > 0"
                    >
                      Resend code in {{ resend_verification_time }} seconds
                    </span>
                    <span
                      class="mt-4"
                      style="color: #003f51; cursor: pointer; display: block"
                      @click="resend_verification_code"
                      v-else
                    >
                      Resend the code
                    </span>
                  </v-stepper-content>
                  <v-stepper-content step="5">
                    <h2 class="mb-6 mt-2">Registration Complete</h2>
                    <span
                      >Redirecting to login page in
                      {{ count_down_time }} seconds</span
                    >
                    <v-img
                      class="mt-1"
                      v-if="!$vuetify.theme.dark"
                      src="@/assets/BETSY_wave_smile.png"
                      max-width="180"
                    ></v-img>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  validateIsNumber,
  validateSamePassword,
  validateEmailRegister,
  validatePassword,
  validateNotEmpty,
  validateNotTooLong,
  validateCountry,
} from "@/utilities/ValidationRules";
import Vue from "vue";
import axios from "axios";
import { eventBus } from "../main.js";

import {
  CognitoIdentityProviderClient,
  ResendConfirmationCodeCommand,
  ConfirmSignUpCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
var cognitoidentityserviceprovider = new CognitoIdentityProviderClient({
  region: process.env.VUE_APP_AWS_REGION,
});

export default {
  name: "Login",
  props: ["register_type"],
  components: {},
  data() {
    return {
      selected_image: 0,
      //

      //
      stepperCount: 1,
      visible: false,

      user: { cognito: {}, dynamo: { country: "Canada" } },
      confirm_password: null,
      cognito_form_valid: false,
      dynamo_form_valid: false,
      referred_form_valid: false,
      verification_form_valid: false,
      countries: [
        "Afghanistan",
        "Aland Islands",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, Democratic Republic of the Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D'Ivoire",
        "Croatia",
        "Cuba",
        "Curacao",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and Mcdonald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran, Islamic Republic of",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia, the Former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestinian Territory, Occupied",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Barthelemy",
        "Saint Helena",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Serbia and Montenegro",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands, British",
        "Virgin Islands, U.s.",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      countries_shortcut: {
        AF: "Afghanistan",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua and Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BS: "Bahamas (the)",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia (Plurinational State of)",
        BQ: "Bonaire, Sint Eustatius and Saba",
        BA: "Bosnia and Herzegovina",
        BW: "Botswana",
        BV: "Bouvet Island",
        BR: "Brazil",
        IO: "British Indian Ocean Territory (the)",
        BN: "Brunei Darussalam",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        CV: "Cabo Verde",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        KY: "Cayman Islands (the)",
        CF: "Central African Republic (the)",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands (the)",
        CO: "Colombia",
        KM: "Comoros (the)",
        CD: "Congo (the Democratic Republic of the)",
        CG: "Congo (the)",
        CK: "Cook Islands (the)",
        CR: "Costa Rica",
        HR: "Croatia",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        CZ: "Czechia",
        CI: "Côte d'Ivoire",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic (the)",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        SZ: "Eswatini",
        ET: "Ethiopia",
        FK: "Falkland Islands (the) [Malvinas]",
        FO: "Faroe Islands (the)",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories (the)",
        GA: "Gabon",
        GM: "Gambia (the)",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard Island and McDonald Islands",
        VA: "Holy See (the)",
        HN: "Honduras",
        HK: "Hong Kong",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran (Islamic Republic of)",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KP: "Korea (the Democratic People's Republic of)",
        KR: "Korea (the Republic of)",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Lao People's Democratic Republic (the)",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands (the)",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia (Federated States of)",
        MD: "Moldova (the Republic of)",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands (the)",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger (the)",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        MP: "Northern Mariana Islands (the)",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestine, State of",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines (the)",
        PN: "Pitcairn",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        MK: "Republic of North Macedonia",
        RO: "Romania",
        RU: "Russian Federation (the)",
        RW: "Rwanda",
        RE: "Réunion",
        BL: "Saint Barthélemy",
        SH: "Saint Helena, Ascension and Tristan da Cunha",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        MF: "Saint Martin (French part)",
        PM: "Saint Pierre and Miquelon",
        VC: "Saint Vincent and the Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome and Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SX: "Sint Maarten (Dutch part)",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia and the South Sandwich Islands",
        SS: "South Sudan",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan (the)",
        SR: "Suriname",
        SJ: "Svalbard and Jan Mayen",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syrian Arab Republic",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania, United Republic of",
        TH: "Thailand",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad and Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks and Caicos Islands (the)",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates (the)",
        GB: "United Kingdom of Great Britain and Northern Ireland (the)",
        UM: "United States Minor Outlying Islands (the)",
        US: "United States of America (the)",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela (Bolivarian Republic of)",
        VN: "Viet Nam",
        VG: "Virgin Islands (British)",
        VI: "Virgin Islands (U.S.)",
        WF: "Wallis and Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        AX: "Åland Islands",
      },
      count_down_time: 4,
      verification_code: null,

      resend_verification_time: 60,
    };
  },
  methods: {
    validateIsNumber,
    validateSamePassword,
    validateEmailRegister,
    validatePassword,
    validateNotEmpty,
    validateNotTooLong,
    validateCountry,

    async resend_verification_code() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        Username: this.user.cognito.email /* required */,
      };
      var _this = this;

      var command = new ResendConfirmationCodeCommand(params);
      cognitoidentityserviceprovider
        .send(command)
        .then(() => {
          var __this = _this;
          _this.resend_verification_time = 60;
          var verification_timer = setInterval(function () {
            __this.resend_verification_time -= 1;
            if (__this.resend_verification_time == 0) {
              clearInterval(verification_timer);
            }
          }, 1000);
        })
        .catch((err) => {
          eventBus.$emit("add_button_alert", {
            msg: `Error sending the verification code`,
            err: true,
          });
          return err;
        });
    },

    verify_email() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        Username: this.user.cognito.email /* required */,
        ConfirmationCode: this.verification_code,
      };

      var _this = this;

      var command = new ConfirmSignUpCommand(params);
      cognitoidentityserviceprovider
        .send(command)
        .then(() => {
          var __this = _this;
          _this.stepperCount = 5;
          var timer = setInterval(function () {
            __this.count_down_time -= 1;
            if (__this.count_down_time == 0) {
              clearInterval(timer);
            }
          }, 1000);
          setTimeout(() => {
            __this.$router.push({ path: "/login" });
          }, 4000);
          eventBus.$emit("add_button_alert", {
            msg: `Successfully verified the email`,
            err: false,
          });
        })
        .catch((err) => {
          eventBus.$emit("add_button_alert", {
            msg: `Error verifying the email`,
            err: true,
          });
          return err;
        });
    },
    register() {
      var params = {
        ClientId: process.env.VUE_APP_WEB_CLIENT_ID /* required */,
        Password: this.user.cognito.password /* required */,
        Username: this.user.cognito.email /* required */,
      };
      var _this = this;

      var command = new SignUpCommand(params);
      cognitoidentityserviceprovider
        .send(command)
        .then((data) => {
          var __this = _this;
          _this.stepperCount = 4;
          let url = process.env.VUE_APP_REGISTRATION_LAMBDA;
          setTimeout(() => {
            var lambda_params = {
              id: data.UserSub,
              first_name: __this.user.dynamo.first_name,
              last_name: __this.user.dynamo.last_name,
              company_name: __this.user.dynamo.company,
              occupation: __this.user.dynamo.occupation,
              email: __this.user.cognito.email,
              country: __this.user.dynamo.country,
              city: __this.user.dynamo.city,
              province: "",
              referred_by: __this.user.dynamo.referred_by,
            };
            let str_lambda_params = JSON.stringify(lambda_params);
            var headers = {
              SignatureHeader: "XYZ",
              "Content-type": "application/json",
              metadata: str_lambda_params,
            };
            let config = {
              method: "POST",
              url: url,
              headers: headers,
            };
            axios(config);
          }, 1500);

          _this.resend_verification_time = 60;
          var verification_timer = setInterval(function () {
            __this.resend_verification_time -= 1;
            if (__this.resend_verification_time == 0) {
              clearInterval(verification_timer);
            }
          }, 1500);
        })
        .catch((err) => {
          eventBus.$emit("add_button_alert", {
            msg: `Error regestering`,
            err: true,
          });
          return err;
        });
    },
  },
  created() {
    this.selected_image = Math.floor(Math.random() * 13);
  },

  mounted() {
    if (this.register_type == "verify-email") {
      this.user.cognito.email = Vue.prototype.$unverifiedUser;
      eventBus.$emit("add_button_alert", {
        msg: `Your email is not verified`,
        err: false,
      });

      this.stepperCount = 4;
      this.resend_verification_code();
    } else if (
      this.register_type.includes("email") &&
      this.register_type.includes("&&")
    ) {
      const url_array = this.register_type.split("&&");
      var user_object = {};
      for (const i in url_array) {
        let sub_url_array = url_array[i].split("=");
        user_object[sub_url_array[0]] = sub_url_array[1];
      }

      this.user.cognito.email = user_object["email"];

      this.user.dynamo.first_name = user_object["name"].split(" ")[0];
      this.user.dynamo.last_name = user_object["name"].split(" ")[1];
      this.user.dynamo.city = user_object["city"];
      this.user.dynamo.country =
        this.countries_shortcut[user_object["country"]];
    }
  },
  watch: {},
};
</script>

<style scoped>
.bg {
  background-color: #0090a4;
  height: 100%;
}
#main_login_image1 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image01.png")
    repeat scroll 0% 0%;
}
#main_login_image2 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image02.png")
    repeat scroll 0% 0%;
}
#main_login_image3 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image03.png")
    repeat scroll 0% 0%;
}
#main_login_image4 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image04.png")
    repeat scroll 0% 0%;
}
#main_login_image5 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image05.png")
    repeat scroll 0% 0%;
}
#main_login_image6 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image06.png")
    repeat scroll 0% 0%;
}
#main_login_image7 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image07.png")
    repeat scroll 0% 0%;
}
#main_login_image8 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image08.png")
    repeat scroll 0% 0%;
}
#main_login_image9 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image09.png")
    repeat scroll 0% 0%;
}
#main_login_image10 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image10.png")
    repeat scroll 0% 0%;
}
#main_login_image11 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image11.png")
    repeat scroll 0% 0%;
}
#main_login_image12 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image12.png")
    repeat scroll 0% 0%;
}
#main_login_image0 {
  background: rgba(0, 0, 0, 0) url("../assets/login_images/login-image13.png")
    repeat scroll 0% 0%;
}
</style>